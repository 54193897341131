/** @jsx jsx */
import { Grid, jsx, Styled } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import { DateTime, Interval } from "luxon";
import me from "../images/me.jpg";
import { FC, Fragment } from "react";
import Helmet from "react-helmet";

const Section: FC<unknown> = ({ children }) => {
  return <div sx={{ padding: 4, position: "relative" }}>{children}</div>;
};

const IndexPage: FC<unknown> = () => {
  const age = Math.floor(
    Interval.fromDateTimes(
      DateTime.fromISO("1984-02-17"),
      DateTime.local()
    ).length("year")
  );
  const exp = Math.floor(
    Interval.fromDateTimes(
      DateTime.fromISO("1984-02-17").plus({ years: 20 }),
      DateTime.local()
    ).length("year")
  );

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang: "fr",
        }}
      >
        <title>{`Josian Godard - Développeur front-end à Toulouse`}</title>
        <meta
          name={"description"}
          content={"Page personnelle de Josian GODARD"}
        />
      </Helmet>
      <div
        sx={{
          width: "480px",
          margin: "0 auto",
          borderRadius: "6px",
          backgroundColor: "#fff",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div>
          <StaticImage
            src="../images/header.jpg"
            alt="Header"
            role="presentation"
            sx={{ borderRadius: "6px 6px 0 0" }}
            layout={"fullWidth"}
          />
        </div>

        <Section>
          <div sx={{ textAlign: "center" }}>
            <div
              sx={{
                width: "120px",
                height: "120px",
                margin: "-100px auto 0",
                marginBottom: 3,
                border: "4px solid #fff",
                borderRadius: "100px",
                backgroundImage: `url(${me})`,
                backgroundSize: "cover",
                "&:hover": {
                  backgroundPosition: "0 bottom",
                },
              }}
            ></div>

            <Grid gap={4}>
              <Styled.h1
                sx={{
                  fontFamily: "Libre Baskerville, serif",
                  fontWeight: "normal",
                  lineHeight: 1.4,
                  textTransform: "uppercase",
                }}
              >
                {`Josian Godard`}
                <small
                  sx={{
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: 2,
                    fontWeight: "900",
                    display: "block",
                    marginTop: "-0.25em",
                  }}
                >{`Développeur front-end`}</small>
              </Styled.h1>

              <Styled.p>
                {`Mon nom est Josian Godard, j'ai ${age} ans et je suis un développeur front-end basé à Toulouse avec plus de ${exp} années d'expérience dans le web.`}
              </Styled.p>

              <Styled.a
                href="mailto:josian.godard@gmail.com"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  display: "block",
                  textAlign: "center",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  color: "#fff",
                  borderBottom: "3px solid rgba(0, 0, 0, 0.25)",
                  borderRadius: ".25rem",
                  backgroundColor: "secondary",
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              >
                {`Me contacter`}
              </Styled.a>
            </Grid>
          </div>
        </Section>

        <div>
          <StaticImage
            src="../images/footer.jpg"
            alt="Footer"
            role="presentation"
            sx={{ borderRadius: "0 0 6px 6px" }}
            layout={"fullWidth"}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IndexPage;
